// import { Link } from "gatsby"
import React from 'react'
import logo_alsassociation from '../style/images/logos/logo_alsassociation.png'
import logo_one2one from '../style/images/logos/logo_one2one.png'
import logo_projectals from '../style/images/logos/logo_projectals.png'

class About extends React.Component {
  render() {
    return (
      <section className="About">
        <h1>Meet Our Partners</h1>
        <div className="wrapper">
          <div className="charity">
            <div>
              <a
                href="https://www.projectals.org/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logo_projectals} alt="Project ALS logo" />
              </a>
            </div>
            <p>
              Project ALS identifies and funds the most promising scientific
              research that will lead to the first effective treatments and a
              cure for ALS. By recruiting the world’s best scientists and
              doctors to work together—rationally and aggressively—we can
              develop a better understanding of the ALS disease process and, in
              parallel, better therapeutic strategies.
            </p>
          </div>
          <div className="charity">
            <div>
              <a
                href="https://one2oneusa.org/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logo_one2one} alt="one2one Foundation logo" />
              </a>
            </div>
            <p>
              one2one USA Foundation transforms the existing charity model by
              connecting individuals directly with those in need in a
              customizable, transparent and impactful way. Our hope is to create
              a community of active Donors who, by helping one person at a time,
              will collectively improve countless lives.
            </p>
          </div>
          <div className="charity">
            <div>
              <a
                href="http://webgw.alsa.org/site/PageServer?pagename=GW_homepage"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={logo_alsassociation}
                  alt="ALS Association Golden West Chapter logo"
                />
              </a>
            </div>
            <p>
              The ALS Association Golden West Chapter is dedicated to the fight
              against ALS in many ways, including funding global research
              efforts, supporting scientific and clinical collaboration,
              connecting people with ALS to clinical trials, partnering with
              multidisciplinary ALS clinics and centers, educating the public
              about ALS, providing professional care management services to
              families facing ALS, pursuing important public policy initiatives,
              and bringing the ALS community together.
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default About
