// import { Link } from "gatsby"
import React from 'react'
import logo_charitynetwork from '../style/images/logos/logo_charitynetwork.png'
import logo_prizeo from '../style/images/logos/logo_prizeo.png'
import logo_charitybuzz from '../style/images/logos/logo_charitybuzz.png'

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <h1>About Charity Network</h1>
        <section className="about-cn">
          <div className="platform charity-network">
            <a
              href="https://www.charitynetwork.com/#how-we-grow-the-pie"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo_charitynetwork} alt="Charity Network logo" />
            </a>
            <p>
              Named one of Fast Company’s 2017 Most Innovative Companies,
              Charity Network harnesses the power of celebrity, technology, and
              media to raise awareness and funds for causes around the world.
              Launched in 2016 by entrepreneur Todd Wagner, Charity Network is
              the parent company to leading online charity auction site
              Charitybuzz and online sweepstakes-for-good platform Prizeo, and
              has helped raise nearly $400 million for cause to date.
            </p>
          </div>
          <div className="platform prizeo">
            <a href="https://www.prizeo.com/" target="_blank" rel="noreferrer">
              <img src={logo_prizeo} alt="Prizeo logo" />
            </a>
            <p>
              Donations to Prizeo sweepstakes begin at a minimum of $10, which
              democratizes giving and allows donors to "give small and win big."
              These online sweepstakes mobilize fan bases by offering
              once-in-a-lifetime experiences, with the winners selected through
              random drawings.
            </p>
          </div>
          <div className="platform charitybuzz">
            <a
              href="https://www.charitybuzz.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo_charitybuzz} alt="Charitybuzz logo" />
            </a>
            <p>
              Charitybuzz caters to a community of hundreds of thousands of
              high-net-worth bidders, offering incredible experiences and luxury
              packages through its premier online auction site.
            </p>
          </div>
        </section>
        <div className="copyright">
          © {new Date().getFullYear()}
          {` `}
          <a href="https://www.charitynetwork.com">Charity Network</a>
        </div>
      </footer>
    )
  }
}

export default Footer
