/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import About from "./About"
import Card from "./Card"
import "../style/sass/style.scss"
import imageJimmyKimmelLive from "../style/images/image_jimmy-kimmel-live.png"
import imageThankYouForYourSupport from "../style/images/image_thank-you-for-your-support.png"
import imageYourPalsAt from "../style/images/image_your-pals-at.png"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
        allContentfulPrizeoCampaign {
          edges {
            node {
              url
              title
              image {
                id
                file {
                  url
                }
              }
              id
            }
          }
        }
        allContentfulCharitybuzzAuction {
          edges {
            node {
              url
              title
              image {
                id
                file {
                  url
                }
              }
              id
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="main-content" id="main-content">
          {children}
          <section className="prizeo">
            <h1>Prizeo Sweepstakes</h1>
            <img src="" alt="" />
            <p>
              At Prizeo, giving small means winning big!
              <br />
              Donate as little as $10 to enter for the chance
              <br />
              to win an unforgettable experience.
            </p>
            <div className="card-container">
              {data.allContentfulPrizeoCampaign.edges.map(edge => (
                <Card
                  key={edge.node.id}
                  title={edge.node.title}
                  link={edge.node.url}
                  imageURL={edge.node.image.file.url}
                  CTA="Enter to Win"
                />
              ))}
            </div>
            {/* <a href="#" className="button secondary">
              Explore More Grand Prizes
            </a> */}
          </section>
          <section className="charitybuzz">
            <h1>Charitybuzz Auctions</h1>
            <p>
              At Charitybuzz, bid to win any of these
              <br />
              exciting experiences and make a real-world
              <br />
              impact in the lives of others.
            </p>
            <div className="card-container">
              {data.allContentfulCharitybuzzAuction.edges.map(edge => (
                <Card
                  key={edge.node.id}
                  title={edge.node.title}
                  link={edge.node.url}
                  imageURL={edge.node.image.file.url}
                  CTA="Bid to Win"
                />
              ))}
            </div>
            <a
              href="https://www.charitybuzz.com/theme/jkl/lots?preview=true"
              className="button secondary"
            >
              Explore More Auctions
            </a>
          </section>
          <div className="teamPhoto">
            {/* <img
              className="imageThankYouForYourSupport"
              src={imageThankYouForYourSupport}
              alt=""
            />
            <img className="imageYourPalsAt" src={imageYourPalsAt} alt="" />
            <img
              className="imageJimmyKimmelLive"
              src={imageJimmyKimmelLive}
              alt=""
            /> */}
            {/* <h2 className="thankYou">
              Thank you for your support!{' '}
              <span className="signature">
                All your pals at Jimmy Kimmel Live!
              </span>
            </h2> */}
          </div>
          <About />
          <Footer />
          {/* <DirectDonate /> */}
          {/* <div className="copyright">
            © {new Date().getFullYear()}
            {` `}
            <a href="https://www.charitynetwork.com">Charity Network</a>
          </div> */}
        </main>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
