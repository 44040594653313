// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from 'react'
import logo_jkl from '../style/images/logos/logo_jkl.png'
import image_jimmyHero from '../style/images/hero-5.png'
import image_bgHero from '../style/images/background.jpg'
import logo_alsassociation from '../style/images/logos/logo_alsassociation.png'
import logo_one2one from '../style/images/logos/logo_one2one.png'
import logo_projectals from '../style/images/logos/logo_projectals.png'
import image_meta from '../style/images/image_meta.jpg'

class Header extends React.Component {
  render() {
    return (
      <div className="Header">
        <div className="wrapper">
          <img className="jimmyHero" src={image_jimmyHero} alt="Jimmy Kimmel" />

          <div className="heroGrid">
            <div className="logoImg">
              <img src={logo_jkl} alt="" />
            </div>
            <div className="heroText">
              <h1 className="hero">
                Win once-in-a-lifetime
                <br />
                experiences supporting
                <br />
                Jimmy Kimmel Live!
                <br />
                in the fight against ALS
              </h1>
              <div className="intro">
                {/* <p>
                  Celebrities, athletes, musicians and Jimmy himself are all
                  together to offer rare experiences you can win to support the
                  fight against ALS, a progressive neurodegenerative disease.
                </p> */}
                <p>
                  One of our longtime and most beloved staffers has been
                  diagnosed with ALS (a progressive neurodegenerative disease).
                  In support of him and the countless others affected by it, the
                  team at “Jimmy Kimmel Live!” is raising money for ALS research
                  to find a cure as well as help patients diagnosed with this
                  devastating disease.
                </p>
                <p>
                  Celebrities, athletes and musicians have all come together to
                  offer rare experiences you can win to support the fight
                  against ALS. Check out these unique experiences and exclusive
                  memorabilia you can win on Charitybuzz and Prizeo below.
                </p>
                <p>Thanks for your support!</p>
                <p>
                  — Jimmy Kimmel and the “Jimmy Kimmel Live!” staff and crew
                </p>
              </div>
            </div>
          </div>
          {/* <a className="button" href="#main-content">
            View Prizes
          </a> */}
        </div>
      </div>
    )
  }
}

export default Header
