import React from 'react'
// import { link } from "fs";

class Card extends React.Component {
  render() {
    return (
      <a
        className="card"
        href={this.props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="card-image"
          src={this.props.imageURL}
          alt={this.props.title}
        />
        {/* <div className="info"> */}
        <h3 className="campaign-title">{this.props.title}</h3>
        <div href={this.props.link} className="button">
          {this.props.CTA}
        </div>
        {/* </div> */}
      </a>
    )
  }
}

export default Card
